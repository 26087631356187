<template>
  <div class="app-simple-search-panel">
    <div class="d-flex flex-row justify-center">
      <v-text-field
        v-model.trim="search.text"
        outline
        dense
        clearable
        :placeholder="searchPlaceholder" 
        class="app-simple-search-panel__input"
      ></v-text-field>
    </div>
  </div>
</template>

<script>

export default {
  name: "SimpleSearchPanel",
  props: {
    search: {
      type: Object,
    },
    searchPlaceholder: {
      type: String,
      default: 'Ваш город',
    },
  },
};
</script>

<style lang="less">
.app-simple-search-panel {
  .app-simple-search-panel__input {
    .v-input__slot {
      min-height: 36px;
      border-width: 1px !important;
      .v-text-field__slot {
        input {
          margin: auto;
        }
      }

      .v-input__append-inner {
        margin: auto;
      }
    }

    .v-text-field__details {
      display: none;
    }
  }
}
</style>
